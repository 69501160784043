<template>
  <div class="content">
    <sub-title>스포츠</sub-title>
    <sports-game-type-selector></sports-game-type-selector>
    <div style="width: 100%; margin-top:.1rem">
      <sports-bonus-comp></sports-bonus-comp>
      <sports-match-comp :game="item" v-if="item.isShow" v-for="(item,idx) in games" :key="idx"></sports-match-comp>
    </div>
    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>
</template>

<script>

import Left from "@/views/afront/Left.vue";
import Header from "@/views/afront/Header.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import SportsGameTypeSelector from "@/views/afront/sports/gametype/SportsGameTypeSelector.vue";
import SportsMatchComp from "@/views/afront/sports/match/SportsMatchComp.vue";
import sportsConst from "@/common/sportsConst";
import {postionMixin, sportsGameCountMixin, sportsMatchMixin} from "@/common/mixin";
import SportsScrollToTop from "@/views/afront/zero/ScrollToTop.vue";
import SportsBonusComp from "@/views/afront/sports//match/SportsBonusComp.vue";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";

export default {
  name: "Sports",
  mixins:[postionMixin,sportsMatchMixin,sportsGameCountMixin],
  components: {
    MyUncheckedMessageComp,
    SportsBonusComp,
    SportsScrollToTop, SportsMatchComp, SportsGameTypeSelector, SubTitle, Footer, Right, Header, Left},
  data() {
    return {
      displayPosition: sportsConst.GAME_DISPLAYPOSITION_1x2,
      games: [],
      leagueId: 0,
      gameType: 0,
      mGames: [],//승무패 more게임클릭시 핸디,오버언더게임,
      position:"스포츠",
    }
  },
  props: {
    pgame: {
      type: Object,
      default() {
        return {
        };
      }
    },

  },
  methods: {

  },
  created() {
    this.$store.state.betcartshowable = true
    this.$store.state.isinplay = false;
    this.$store.state.displayPostion =  sportsConst.GAME_DISPLAYPOSITION_1x2
    this.$store.state.selectedGameType=sportsConst.GAME_TYPE_NOT_SELECTED
    this.$store.state.selectedLeagueList=[]
    this.$store.state.leagueList = [];
    this.getGameCount();
    this.initLeagues();
    this.getSportsGames();
  },
  watch: {
    "$store.state.selectedGameType": {
      deep: true,
      handler: function (newVal) {
        if (this.$store.state.selectedGameType=== sportsConst.GAME_TYPE_ALL || this.$store.state.selectedGameType=== sportsConst.GAME_TYPE_NOT_SELECTED) {
          this.$store.state.selectedLeagueList=[];
        }
        this.games.forEach(g => {
          this.settingGamesShow(g);
        })
      }
    },
    "$store.state.selectedLeagueList": {
      deep: true,
      handler: function (newVal) {
        this.games.forEach(g => {
          this.settingGamesShow(g);
        })
      }
    }
  }
}
</script>

<style scoped>
@import url("../../../../assets/css/front/afrontsports.css");
</style>